<script lang="ts" setup>
	import { Sizes } from "~/utils/enums";

	const props = withDefaults(
		defineProps<{
			url?: string;
			size: Sizes;
			name: string;
		}>(),
		{
			size: "small",
		}
	);
	const monogramm = computed(() => {
		const names = props.name.split(" ");
		return names[0][0] + names[1][0];
	});
</script>

<template>
	<div class="profile-picture" :class="[size]">
		<h2 v-if="!url">{{ monogramm }}</h2>
		<img v-if="url" :src="url" />
	</div>
</template>

<style scoped lang="scss">
	.profile-picture {
		display: flex;
		background: $grey;
		overflow: hidden;
		align-items: center;
		justify-content: center;

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		h2 {
		}
		&.small {
			width: 40px;
			height: 40px;
			border-radius: 20px;
		}

		&.medium {
			width: 80px;
			height: 80px;
			border-radius: 40px;
		}

		&.large {
			width: 160px;
			height: 160px;
			border-radius: 80px;
		}
	}
</style>
